@import url('https://fonts.googleapis.com/css?family=Oswald:300,400|Baloo+Da+2:400,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*{
    margin:0;
    padding:0;
/*     box-sizing: border-box; */
}

.container {
  display: flex;
  padding: 60px;
  margin-left: 60px;
}
.logo img {
  width: 110px;
  /* opacity: 2; */
  /* padding: 20px; */
  /**/
}
.content {
  margin-top: 80px;
  width: 700px;
  margin-right: 80px;
  /* border: #00e4ff solid 1px; */
}
.content h1 {
  margin-bottom: 0;
  line-height: 0.9em;
  font-size: 45px;
  color: #abdfdc;
}
.content h6 {
  margin-top: 10px;
  font-size: 20px;
  color: #aaf489;
  margin-bottom: 0;
}
.btnContainer {
  width: 700px;
}
.btnContainer > p {
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
  line-height: 23px;
}
.btnContainer h3 {
  margin-top: 10px;
}
.btn p {
  margin-right: 0;
  font-size: 18px;
  line-height: 18px;
}
/* button {
  color: white;
  transition: all 0.5s ease;
  margin-top: 15px;
  margin-left: 0;
  text-decoration: none;
}
button:hover {
  color: #00e4ff;
  text-decoration: none;
} */

.btn-gradient {
  transition: 0.5s;
  background-size: 200% auto;
  color: #270268;
  font-weight: 600;
  background-color: #abdfdc;
  border: 2px solid #270268;
  border-radius: 1.5625em;
  padding: 0.825em 1.5625em;
  /* font-weight: 500; */
  text-transform: uppercase;
  margin-top:   3% ;
  margin-bottom:   3% ;
}
.btn-gradient:hover {
  background-position: right center;
  color: #270268;
  border-color: #abdfdc;
}
.rightSide img {
  margin-top: 5%;
  margin-right: 7%;

  /* margin-right: 0; */
}

.redheart {
  color: red;
  font-size: 1.1em;
}

.redheart:hover {
  font-size: 1.4em;
}
footer {
  position: absolute;
  bottom: 0;
  left: 30%;
  display: flex;
  justify-content: space-between;
  font-size: 0.75em;
}
/* .copyright p {
  
} */

.wrapper {
  display: inline-flex;
  list-style: none;
  /* border: 2px solid black; */
}

.wrapper .icon {
  position: relative;
  margin-top: 0.02em;
  margin-left: 0.9em;
  /* width: 50px;
  height: 50px; */
  font-size: 10px;
  display: flex;
  /* justify-content: center;
  align-items: center;
  flex-direction: column; */
  /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: '';
  height: 6px;
  width: 6px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #ffffff;
}

.wrapper .linkedin:hover .tooltip,
.wrapper .linkedin:hover .tooltip::before {
  background: #0a66c2;
  color: #ffffff;
}

.wrapper .email:hover .tooltip,
.wrapper .email:hover .tooltip::before {
  background: #d93025;
  color: #ffffff;
}

html {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 279px 109px #fff, 1361px 932px #fff, 1219px 1741px #fff,
    940px 704px #fff, 493px 1376px #fff, 1635px 1766px #fff, 1412px 560px #fff,
    566px 802px #fff, 136px 1555px #fff, 302px 199px #fff, 1771px 205px #fff,
    1289px 917px #fff, 1234px 439px #fff, 593px 208px #fff, 580px 1720px #fff,
    684px 571px #fff, 185px 1791px #fff, 20px 513px #fff, 795px 1303px #fff,
    1993px 1535px #fff, 1183px 1297px #fff, 99px 1182px #fff, 1491px 1610px #fff,
    803px 468px #fff, 1061px 998px #fff, 1570px 270px #fff, 748px 98px #fff,
    1552px 1135px #fff, 1408px 1029px #fff, 687px 284px #fff, 1861px 102px #fff,
    912px 919px #fff, 1020px 44px #fff, 1138px 544px #fff, 1565px 1465px #fff,
    542px 150px #fff, 1059px 727px #fff, 894px 1904px #fff, 1758px 816px #fff,
    1814px 1165px #fff, 41px 50px #fff, 1223px 1709px #fff, 1071px 1464px #fff,
    1465px 1338px #fff, 683px 1905px #fff, 1384px 1333px #fff, 298px 452px #fff,
    718px 1024px #fff, 940px 1177px #fff, 1919px 1784px #fff, 1305px 998px #fff,
    1743px 275px #fff, 563px 626px #fff, 874px 495px #fff, 1390px 605px #fff,
    1291px 411px #fff, 1635px 1740px #fff, 1068px 1688px #fff, 300px 201px #fff,
    1040px 325px #fff, 1816px 1512px #fff, 473px 810px #fff, 1169px 709px #fff,
    1324px 1029px #fff, 849px 1456px #fff, 852px 1035px #fff, 1555px 333px #fff,
    382px 1311px #fff, 1741px 1758px #fff, 93px 878px #fff, 942px 1458px #fff,
    1414px 1848px #fff, 1560px 1126px #fff, 1135px 1180px #fff,
    1084px 441px #fff, 222px 1193px #fff, 937px 707px #fff, 640px 384px #fff,
    241px 892px #fff, 91px 140px #fff, 262px 625px #fff, 1707px 793px #fff,
    977px 1177px #fff, 1705px 1827px #fff, 595px 77px #fff, 1186px 866px #fff,
    1439px 828px #fff, 1583px 104px #fff, 976px 107px #fff, 571px 1837px #fff,
    506px 579px #fff, 383px 549px #fff, 1991px 652px #fff, 1573px 190px #fff,
    162px 1426px #fff, 1390px 1097px #fff, 929px 571px #fff, 861px 249px #fff,
    407px 257px #fff, 640px 1771px #fff, 379px 373px #fff, 1606px 1942px #fff,
    761px 118px #fff, 1203px 926px #fff, 132px 794px #fff, 478px 1106px #fff,
    651px 700px #fff, 1979px 97px #fff, 1617px 833px #fff, 1545px 1360px #fff,
    127px 1526px #fff, 1028px 1958px #fff, 1624px 1802px #fff,
    1854px 1902px #fff, 1232px 246px #fff, 1923px 1385px #fff, 66px 797px #fff,
    118px 763px #fff, 907px 1768px #fff, 874px 1971px #fff, 40px 1266px #fff,
    869px 1707px #fff, 476px 1735px #fff, 940px 883px #fff, 207px 186px #fff,
    921px 598px #fff, 1747px 1396px #fff, 387px 1711px #fff, 1126px 1738px #fff,
    572px 1488px #fff, 87px 1342px #fff, 753px 1975px #fff, 1152px 403px #fff,
    474px 555px #fff, 1932px 1218px #fff, 602px 1025px #fff, 1612px 1152px #fff,
    122px 724px #fff, 748px 957px #fff, 698px 1154px #fff, 1250px 1316px #fff,
    437px 1368px #fff, 701px 834px #fff, 1087px 1418px #fff, 1883px 1719px #fff,
    852px 1021px #fff, 172px 1644px #fff, 795px 561px #fff, 1962px 1156px #fff,
    1361px 1147px #fff, 1810px 934px #fff, 676px 827px #fff, 835px 143px #fff,
    1412px 927px #fff, 1911px 1212px #fff, 184px 1844px #fff, 1989px 1264px #fff,
    64px 117px #fff, 1891px 1110px #fff, 800px 691px #fff, 1463px 1156px #fff,
    1604px 68px #fff, 656px 1174px #fff, 439px 1205px #fff, 684px 858px #fff,
    352px 878px #fff, 671px 1725px #fff, 1087px 1177px #fff, 1753px 471px #fff,
    1491px 811px #fff, 1046px 522px #fff, 592px 935px #fff, 1976px 42px #fff,
    488px 1716px #fff, 392px 377px #fff, 1974px 1672px #fff, 407px 1675px #fff,
    1775px 545px #fff, 1539px 398px #fff, 1544px 1174px #fff, 608px 223px #fff,
    1324px 1915px #fff, 1482px 1322px #fff, 158px 1713px #fff, 552px 194px #fff,
    1093px 1339px #fff, 1777px 198px #fff, 1719px 1593px #fff, 985px 1366px #fff,
    90px 494px #fff, 120px 248px #fff, 1912px 765px #fff, 1406px 1283px #fff,
    1358px 725px #fff, 255px 1117px #fff, 700px 1925px #fff, 1661px 754px #fff,
    1139px 953px #fff, 823px 900px #fff, 1888px 835px #fff, 1069px 1464px #fff,
    795px 1751px #fff, 1359px 1379px #fff, 628px 574px #fff, 1670px 397px #fff,
    23px 677px #fff, 1758px 507px #fff, 1292px 456px #fff, 1744px 1608px #fff,
    1164px 1643px #fff, 463px 936px #fff, 174px 1627px #fff, 1842px 357px #fff,
    1740px 955px #fff, 1678px 1914px #fff, 1335px 1242px #fff,
    1252px 1550px #fff, 748px 1901px #fff, 1125px 1212px #fff, 994px 498px #fff,
    1415px 964px #fff, 1405px 490px #fff, 1001px 1054px #fff, 188px 1713px #fff,
    1613px 1520px #fff, 413px 1208px #fff, 1262px 286px #fff, 219px 121px #fff,
    727px 345px #fff, 1420px 918px #fff, 1296px 1935px #fff, 961px 939px #fff,
    499px 335px #fff, 262px 1881px #fff, 1701px 1565px #fff, 19px 551px #fff,
    102px 869px #fff, 1284px 606px #fff, 154px 1583px #fff, 26px 157px #fff,
    1300px 1263px #fff, 820px 553px #fff, 182px 957px #fff, 602px 807px #fff,
    39px 1638px #fff, 325px 1648px #fff, 1246px 398px #fff, 1692px 1468px #fff,
    1148px 1926px #fff, 1084px 1988px #fff, 952px 1047px #fff, 165px 914px #fff,
    1945px 1106px #fff, 169px 1065px #fff, 1315px 515px #fff, 1742px 1636px #fff,
    1934px 443px #fff, 1894px 259px #fff, 1578px 1873px #fff, 1922px 961px #fff,
    1918px 389px #fff, 1822px 1990px #fff, 1876px 16px #fff, 1405px 827px #fff,
    750px 165px #fff, 519px 191px #fff, 1818px 1897px #fff, 1042px 944px #fff,
    116px 692px #fff, 202px 711px #fff, 1094px 198px #fff, 1165px 919px #fff,
    1000px 1381px #fff, 1559px 1413px #fff, 1517px 61px #fff, 562px 388px #fff,
    1274px 899px #fff, 1383px 1479px #fff, 1239px 910px #fff, 1832px 1697px #fff,
    730px 1690px #fff, 1075px 1445px #fff, 601px 1975px #fff, 743px 1488px #fff,
    136px 548px #fff, 1166px 244px #fff, 1474px 1478px #fff, 651px 1337px #fff,
    1384px 1036px #fff, 1297px 1274px #fff, 575px 1516px #fff, 1607px 685px #fff,
    1110px 654px #fff, 1007px 1068px #fff, 1316px 1503px #fff, 245px 764px #fff,
    256px 1789px #fff, 666px 990px #fff, 1979px 816px #fff, 1992px 170px #fff,
    310px 298px #fff, 1008px 416px #fff, 865px 1880px #fff, 1431px 168px #fff,
    260px 734px #fff, 1054px 1770px #fff, 1551px 1644px #fff, 649px 1381px #fff,
    190px 294px #fff, 1414px 29px #fff, 463px 1477px #fff, 1687px 746px #fff,
    507px 755px #fff, 1652px 630px #fff, 1759px 799px #fff, 1334px 1387px #fff,
    1438px 1389px #fff, 1192px 1576px #fff, 1691px 398px #fff,
    1875px 1937px #fff, 1682px 1017px #fff, 1019px 693px #fff,
    1480px 1814px #fff, 49px 988px #fff, 1615px 995px #fff, 537px 1139px #fff,
    263px 590px #fff, 71px 883px #fff, 1598px 1377px #fff, 1507px 10px #fff,
    1694px 613px #fff, 1820px 1789px #fff, 241px 630px #fff, 1486px 1798px #fff,
    286px 1362px #fff, 1218px 743px #fff, 1506px 518px #fff, 1086px 1303px #fff,
    1760px 1139px #fff, 1521px 1169px #fff, 490px 1324px #fff,
    1640px 1075px #fff, 493px 940px #fff, 942px 1582px #fff, 1249px 807px #fff,
    1765px 1354px #fff, 7px 1362px #fff, 764px 1296px #fff, 350px 1748px #fff,
    541px 1628px #fff, 1369px 508px #fff, 508px 1298px #fff, 694px 1113px #fff,
    198px 1199px #fff, 1865px 1891px #fff, 755px 1698px #fff, 787px 1582px #fff,
    1191px 1206px #fff, 1924px 1946px #fff, 1322px 1908px #fff,
    339px 1206px #fff, 1514px 1841px #fff, 1169px 1254px #fff, 560px 214px #fff,
    1659px 248px #fff, 1220px 226px #fff, 1105px 1540px #fff, 1495px 699px #fff,
    1250px 1322px #fff, 1536px 1046px #fff, 119px 1508px #fff,
    1945px 1710px #fff, 1848px 659px #fff, 1503px 639px #fff, 762px 1147px #fff,
    58px 960px #fff, 1886px 1405px #fff, 1795px 1987px #fff, 73px 1815px #fff,
    1898px 1869px #fff, 1967px 143px #fff, 1818px 1310px #fff, 1766px 848px #fff,
    884px 1565px #fff, 936px 95px #fff, 656px 1370px #fff, 1194px 1929px #fff,
    1218px 590px #fff, 1475px 1387px #fff, 1462px 1166px #fff, 13px 1627px #fff,
    1203px 1120px #fff, 935px 1007px #fff, 1180px 2000px #fff, 1194px 291px #fff,
    664px 793px #fff, 125px 412px #fff, 519px 1253px #fff, 245px 725px #fff,
    1993px 898px #fff, 290px 959px #fff, 785px 1181px #fff, 1810px 238px #fff,
    753px 205px #fff, 548px 1377px #fff, 1533px 1625px #fff, 373px 818px #fff,
    1677px 1447px #fff, 1368px 1897px #fff, 1717px 902px #fff, 738px 171px #fff,
    348px 1121px #fff, 1890px 1212px #fff, 388px 109px #fff, 1164px 228px #fff,
    1197px 94px #fff, 53px 406px #fff, 1683px 1730px #fff, 1847px 554px #fff,
    1752px 1954px #fff, 178px 162px #fff, 368px 1830px #fff, 156px 111px #fff,
    1755px 345px #fff, 1852px 1924px #fff, 653px 13px #fff, 954px 707px #fff,
    1309px 769px #fff, 396px 1808px #fff, 1795px 1659px #fff, 249px 637px #fff,
    1019px 1603px #fff, 883px 971px #fff, 1562px 1635px #fff, 1482px 1366px #fff,
    1812px 1136px #fff, 1504px 293px #fff, 1172px 1883px #fff, 1452px 12px #fff,
    367px 1311px #fff, 1303px 268px #fff, 1659px 1894px #fff, 448px 1779px #fff,
    1523px 275px #fff, 1466px 47px #fff, 96px 1835px #fff, 705px 1978px #fff,
    1211px 34px #fff, 1861px 277px #fff, 324px 450px #fff, 131px 967px #fff,
    1730px 1888px #fff, 876px 724px #fff, 828px 781px #fff, 200px 1139px #fff,
    1284px 206px #fff, 1417px 440px #fff, 60px 1838px #fff, 1054px 1113px #fff,
    1635px 1007px #fff, 1043px 1695px #fff, 1421px 1586px #fff, 779px 282px #fff,
    473px 1868px #fff, 1509px 1310px #fff, 940px 1733px #fff, 1882px 1616px #fff,
    80px 610px #fff, 1882px 1588px #fff, 1688px 1719px #fff, 1872px 1790px #fff,
    155px 169px #fff, 857px 1970px #fff, 1126px 1974px #fff, 39px 1657px #fff,
    1374px 1080px #fff, 497px 606px #fff, 330px 1034px #fff, 84px 68px #fff,
    1141px 865px #fff, 1039px 368px #fff, 1295px 812px #fff, 1110px 1370px #fff,
    1933px 457px #fff, 1825px 792px #fff, 1050px 317px #fff, 433px 606px #fff,
    1947px 1447px #fff, 1585px 893px #fff, 963px 112px #fff, 564px 866px #fff,
    1876px 989px #fff, 975px 1395px #fff, 621px 994px #fff, 983px 1130px #fff,
    451px 114px #fff, 670px 1458px #fff, 1227px 1920px #fff, 1401px 202px #fff,
    437px 1907px #fff, 1443px 1439px #fff, 1535px 475px #fff, 393px 186px #fff,
    294px 1770px #fff, 1399px 1601px #fff, 1888px 348px #fff, 149px 1793px #fff,
    1257px 399px #fff, 1133px 1250px #fff, 1693px 790px #fff, 14px 1031px #fff,
    1672px 40px #fff, 1339px 1165px #fff, 1523px 486px #fff, 612px 1808px #fff,
    1892px 1593px #fff, 644px 1213px #fff, 1990px 1120px #fff, 1179px 175px #fff,
    105px 1119px #fff, 1319px 1371px #fff, 3px 673px #fff, 519px 1741px #fff,
    1104px 1794px #fff, 1888px 103px #fff, 149px 1468px #fff, 600px 1765px #fff,
    897px 11px #fff, 303px 1645px #fff, 348px 1422px #fff, 499px 854px #fff,
    416px 1355px #fff, 842px 848px #fff, 1342px 1032px #fff, 1906px 618px #fff,
    381px 1736px #fff, 1273px 1980px #fff, 1044px 1741px #fff,
    1331px 1885px #fff, 1392px 1770px #fff, 1972px 985px #fff, 214px 397px #fff,
    1447px 607px #fff, 1036px 589px #fff, 1478px 514px #fff, 1870px 1000px #fff,
    1906px 53px #fff, 1333px 1037px #fff, 1417px 500px #fff, 997px 14px #fff,
    1268px 1764px #fff, 1190px 1056px #fff, 1371px 611px #fff, 1269px 272px #fff,
    181px 177px #fff, 992px 1449px #fff, 138px 548px #fff, 454px 1881px #fff,
    749px 816px #fff, 1395px 525px #fff, 562px 223px #fff, 1414px 1371px #fff,
    1776px 1581px #fff, 1364px 1552px #fff, 906px 1605px #fff, 1569px 88px #fff,
    1530px 822px #fff, 859px 1748px #fff, 589px 1276px #fff, 1730px 343px #fff,
    1077px 1497px #fff, 586px 1539px #fff, 1157px 220px #fff, 62px 218px #fff,
    1602px 310px #fff, 942px 1887px #fff, 549px 1081px #fff, 1484px 1901px #fff,
    788px 959px #fff, 1986px 91px #fff, 1237px 693px #fff, 395px 1291px #fff,
    351px 625px #fff, 1379px 1980px #fff, 372px 336px #fff, 993px 216px #fff,
    432px 1380px #fff, 28px 20px #fff, 336px 759px #fff, 1835px 103px #fff,
    1069px 1473px #fff, 1803px 1119px #fff, 829px 991px #fff, 650px 168px #fff,
    61px 268px #fff, 1937px 1463px #fff, 1028px 731px #fff, 1369px 1458px #fff,
    1202px 698px #fff, 1410px 980px #fff, 521px 920px #fff, 221px 140px #fff,
    667px 1149px #fff, 1423px 1398px #fff, 721px 756px #fff, 447px 1096px #fff,
    628px 1913px #fff, 47px 923px #fff, 1711px 19px #fff, 747px 15px #fff,
    1154px 17px #fff, 875px 860px #fff, 1185px 159px #fff, 994px 1203px #fff,
    188px 1829px #fff, 550px 1861px #fff, 789px 106px #fff, 195px 1468px #fff,
    1047px 1109px #fff, 717px 144px #fff, 1561px 972px #fff, 1086px 1077px #fff,
    591px 211px #fff, 283px 683px #fff, 691px 572px #fff, 916px 895px #fff,
    205px 921px #fff, 1413px 1172px #fff, 1651px 1170px #fff, 1612px 247px #fff,
    989px 1566px #fff, 1739px 1813px #fff, 939px 180px #fff, 16px 1490px #fff,
    1050px 751px #fff, 715px 1645px #fff, 860px 1656px #fff, 1959px 1306px #fff,
    1225px 1674px #fff, 1431px 1058px #fff, 536px 284px #fff, 1431px 1405px #fff,
    1553px 175px #fff, 116px 1545px #fff, 332px 956px #fff, 69px 1060px #fff,
    188px 1576px #fff, 1646px 1901px #fff, 103px 491px #fff, 997px 1083px #fff,
    1003px 998px #fff, 47px 1572px #fff, 942px 779px #fff, 1697px 408px #fff,
    480px 610px #fff, 1879px 301px #fff, 261px 1679px #fff, 1839px 378px #fff,
    1336px 1983px #fff, 849px 1745px #fff, 1879px 1861px #fff,
    1890px 1331px #fff, 938px 1854px #fff, 835px 1655px #fff, 724px 1829px #fff,
    584px 1004px #fff, 1182px 762px #fff, 1095px 1642px #fff, 1079px 1670px #fff,
    698px 691px #fff, 659px 465px #fff, 1817px 1768px #fff, 1012px 1582px #fff,
    422px 1982px #fff, 152px 129px #fff, 809px 1586px #fff, 471px 296px #fff,
    1790px 949px #fff, 959px 1792px #fff, 1353px 150px #fff, 1859px 1944px #fff,
    506px 732px #fff, 909px 227px #fff, 1923px 126px #fff, 1191px 1362px #fff,
    1256px 278px #fff, 1617px 896px #fff, 1166px 1763px #fff, 1070px 1873px #fff,
    1730px 1044px #fff, 129px 1920px #fff, 969px 325px #fff, 1565px 903px #fff,
    1902px 1697px #fff, 1619px 960px #fff, 489px 1612px #fff, 828px 363px #fff,
    1729px 1690px #fff, 1728px 184px #fff, 826px 1747px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 279px 109px #fff, 1361px 932px #fff, 1219px 1741px #fff,
    940px 704px #fff, 493px 1376px #fff, 1635px 1766px #fff, 1412px 560px #fff,
    566px 802px #fff, 136px 1555px #fff, 302px 199px #fff, 1771px 205px #fff,
    1289px 917px #fff, 1234px 439px #fff, 593px 208px #fff, 580px 1720px #fff,
    684px 571px #fff, 185px 1791px #fff, 20px 513px #fff, 795px 1303px #fff,
    1993px 1535px #fff, 1183px 1297px #fff, 99px 1182px #fff, 1491px 1610px #fff,
    803px 468px #fff, 1061px 998px #fff, 1570px 270px #fff, 748px 98px #fff,
    1552px 1135px #fff, 1408px 1029px #fff, 687px 284px #fff, 1861px 102px #fff,
    912px 919px #fff, 1020px 44px #fff, 1138px 544px #fff, 1565px 1465px #fff,
    542px 150px #fff, 1059px 727px #fff, 894px 1904px #fff, 1758px 816px #fff,
    1814px 1165px #fff, 41px 50px #fff, 1223px 1709px #fff, 1071px 1464px #fff,
    1465px 1338px #fff, 683px 1905px #fff, 1384px 1333px #fff, 298px 452px #fff,
    718px 1024px #fff, 940px 1177px #fff, 1919px 1784px #fff, 1305px 998px #fff,
    1743px 275px #fff, 563px 626px #fff, 874px 495px #fff, 1390px 605px #fff,
    1291px 411px #fff, 1635px 1740px #fff, 1068px 1688px #fff, 300px 201px #fff,
    1040px 325px #fff, 1816px 1512px #fff, 473px 810px #fff, 1169px 709px #fff,
    1324px 1029px #fff, 849px 1456px #fff, 852px 1035px #fff, 1555px 333px #fff,
    382px 1311px #fff, 1741px 1758px #fff, 93px 878px #fff, 942px 1458px #fff,
    1414px 1848px #fff, 1560px 1126px #fff, 1135px 1180px #fff,
    1084px 441px #fff, 222px 1193px #fff, 937px 707px #fff, 640px 384px #fff,
    241px 892px #fff, 91px 140px #fff, 262px 625px #fff, 1707px 793px #fff,
    977px 1177px #fff, 1705px 1827px #fff, 595px 77px #fff, 1186px 866px #fff,
    1439px 828px #fff, 1583px 104px #fff, 976px 107px #fff, 571px 1837px #fff,
    506px 579px #fff, 383px 549px #fff, 1991px 652px #fff, 1573px 190px #fff,
    162px 1426px #fff, 1390px 1097px #fff, 929px 571px #fff, 861px 249px #fff,
    407px 257px #fff, 640px 1771px #fff, 379px 373px #fff, 1606px 1942px #fff,
    761px 118px #fff, 1203px 926px #fff, 132px 794px #fff, 478px 1106px #fff,
    651px 700px #fff, 1979px 97px #fff, 1617px 833px #fff, 1545px 1360px #fff,
    127px 1526px #fff, 1028px 1958px #fff, 1624px 1802px #fff,
    1854px 1902px #fff, 1232px 246px #fff, 1923px 1385px #fff, 66px 797px #fff,
    118px 763px #fff, 907px 1768px #fff, 874px 1971px #fff, 40px 1266px #fff,
    869px 1707px #fff, 476px 1735px #fff, 940px 883px #fff, 207px 186px #fff,
    921px 598px #fff, 1747px 1396px #fff, 387px 1711px #fff, 1126px 1738px #fff,
    572px 1488px #fff, 87px 1342px #fff, 753px 1975px #fff, 1152px 403px #fff,
    474px 555px #fff, 1932px 1218px #fff, 602px 1025px #fff, 1612px 1152px #fff,
    122px 724px #fff, 748px 957px #fff, 698px 1154px #fff, 1250px 1316px #fff,
    437px 1368px #fff, 701px 834px #fff, 1087px 1418px #fff, 1883px 1719px #fff,
    852px 1021px #fff, 172px 1644px #fff, 795px 561px #fff, 1962px 1156px #fff,
    1361px 1147px #fff, 1810px 934px #fff, 676px 827px #fff, 835px 143px #fff,
    1412px 927px #fff, 1911px 1212px #fff, 184px 1844px #fff, 1989px 1264px #fff,
    64px 117px #fff, 1891px 1110px #fff, 800px 691px #fff, 1463px 1156px #fff,
    1604px 68px #fff, 656px 1174px #fff, 439px 1205px #fff, 684px 858px #fff,
    352px 878px #fff, 671px 1725px #fff, 1087px 1177px #fff, 1753px 471px #fff,
    1491px 811px #fff, 1046px 522px #fff, 592px 935px #fff, 1976px 42px #fff,
    488px 1716px #fff, 392px 377px #fff, 1974px 1672px #fff, 407px 1675px #fff,
    1775px 545px #fff, 1539px 398px #fff, 1544px 1174px #fff, 608px 223px #fff,
    1324px 1915px #fff, 1482px 1322px #fff, 158px 1713px #fff, 552px 194px #fff,
    1093px 1339px #fff, 1777px 198px #fff, 1719px 1593px #fff, 985px 1366px #fff,
    90px 494px #fff, 120px 248px #fff, 1912px 765px #fff, 1406px 1283px #fff,
    1358px 725px #fff, 255px 1117px #fff, 700px 1925px #fff, 1661px 754px #fff,
    1139px 953px #fff, 823px 900px #fff, 1888px 835px #fff, 1069px 1464px #fff,
    795px 1751px #fff, 1359px 1379px #fff, 628px 574px #fff, 1670px 397px #fff,
    23px 677px #fff, 1758px 507px #fff, 1292px 456px #fff, 1744px 1608px #fff,
    1164px 1643px #fff, 463px 936px #fff, 174px 1627px #fff, 1842px 357px #fff,
    1740px 955px #fff, 1678px 1914px #fff, 1335px 1242px #fff,
    1252px 1550px #fff, 748px 1901px #fff, 1125px 1212px #fff, 994px 498px #fff,
    1415px 964px #fff, 1405px 490px #fff, 1001px 1054px #fff, 188px 1713px #fff,
    1613px 1520px #fff, 413px 1208px #fff, 1262px 286px #fff, 219px 121px #fff,
    727px 345px #fff, 1420px 918px #fff, 1296px 1935px #fff, 961px 939px #fff,
    499px 335px #fff, 262px 1881px #fff, 1701px 1565px #fff, 19px 551px #fff,
    102px 869px #fff, 1284px 606px #fff, 154px 1583px #fff, 26px 157px #fff,
    1300px 1263px #fff, 820px 553px #fff, 182px 957px #fff, 602px 807px #fff,
    39px 1638px #fff, 325px 1648px #fff, 1246px 398px #fff, 1692px 1468px #fff,
    1148px 1926px #fff, 1084px 1988px #fff, 952px 1047px #fff, 165px 914px #fff,
    1945px 1106px #fff, 169px 1065px #fff, 1315px 515px #fff, 1742px 1636px #fff,
    1934px 443px #fff, 1894px 259px #fff, 1578px 1873px #fff, 1922px 961px #fff,
    1918px 389px #fff, 1822px 1990px #fff, 1876px 16px #fff, 1405px 827px #fff,
    750px 165px #fff, 519px 191px #fff, 1818px 1897px #fff, 1042px 944px #fff,
    116px 692px #fff, 202px 711px #fff, 1094px 198px #fff, 1165px 919px #fff,
    1000px 1381px #fff, 1559px 1413px #fff, 1517px 61px #fff, 562px 388px #fff,
    1274px 899px #fff, 1383px 1479px #fff, 1239px 910px #fff, 1832px 1697px #fff,
    730px 1690px #fff, 1075px 1445px #fff, 601px 1975px #fff, 743px 1488px #fff,
    136px 548px #fff, 1166px 244px #fff, 1474px 1478px #fff, 651px 1337px #fff,
    1384px 1036px #fff, 1297px 1274px #fff, 575px 1516px #fff, 1607px 685px #fff,
    1110px 654px #fff, 1007px 1068px #fff, 1316px 1503px #fff, 245px 764px #fff,
    256px 1789px #fff, 666px 990px #fff, 1979px 816px #fff, 1992px 170px #fff,
    310px 298px #fff, 1008px 416px #fff, 865px 1880px #fff, 1431px 168px #fff,
    260px 734px #fff, 1054px 1770px #fff, 1551px 1644px #fff, 649px 1381px #fff,
    190px 294px #fff, 1414px 29px #fff, 463px 1477px #fff, 1687px 746px #fff,
    507px 755px #fff, 1652px 630px #fff, 1759px 799px #fff, 1334px 1387px #fff,
    1438px 1389px #fff, 1192px 1576px #fff, 1691px 398px #fff,
    1875px 1937px #fff, 1682px 1017px #fff, 1019px 693px #fff,
    1480px 1814px #fff, 49px 988px #fff, 1615px 995px #fff, 537px 1139px #fff,
    263px 590px #fff, 71px 883px #fff, 1598px 1377px #fff, 1507px 10px #fff,
    1694px 613px #fff, 1820px 1789px #fff, 241px 630px #fff, 1486px 1798px #fff,
    286px 1362px #fff, 1218px 743px #fff, 1506px 518px #fff, 1086px 1303px #fff,
    1760px 1139px #fff, 1521px 1169px #fff, 490px 1324px #fff,
    1640px 1075px #fff, 493px 940px #fff, 942px 1582px #fff, 1249px 807px #fff,
    1765px 1354px #fff, 7px 1362px #fff, 764px 1296px #fff, 350px 1748px #fff,
    541px 1628px #fff, 1369px 508px #fff, 508px 1298px #fff, 694px 1113px #fff,
    198px 1199px #fff, 1865px 1891px #fff, 755px 1698px #fff, 787px 1582px #fff,
    1191px 1206px #fff, 1924px 1946px #fff, 1322px 1908px #fff,
    339px 1206px #fff, 1514px 1841px #fff, 1169px 1254px #fff, 560px 214px #fff,
    1659px 248px #fff, 1220px 226px #fff, 1105px 1540px #fff, 1495px 699px #fff,
    1250px 1322px #fff, 1536px 1046px #fff, 119px 1508px #fff,
    1945px 1710px #fff, 1848px 659px #fff, 1503px 639px #fff, 762px 1147px #fff,
    58px 960px #fff, 1886px 1405px #fff, 1795px 1987px #fff, 73px 1815px #fff,
    1898px 1869px #fff, 1967px 143px #fff, 1818px 1310px #fff, 1766px 848px #fff,
    884px 1565px #fff, 936px 95px #fff, 656px 1370px #fff, 1194px 1929px #fff,
    1218px 590px #fff, 1475px 1387px #fff, 1462px 1166px #fff, 13px 1627px #fff,
    1203px 1120px #fff, 935px 1007px #fff, 1180px 2000px #fff, 1194px 291px #fff,
    664px 793px #fff, 125px 412px #fff, 519px 1253px #fff, 245px 725px #fff,
    1993px 898px #fff, 290px 959px #fff, 785px 1181px #fff, 1810px 238px #fff,
    753px 205px #fff, 548px 1377px #fff, 1533px 1625px #fff, 373px 818px #fff,
    1677px 1447px #fff, 1368px 1897px #fff, 1717px 902px #fff, 738px 171px #fff,
    348px 1121px #fff, 1890px 1212px #fff, 388px 109px #fff, 1164px 228px #fff,
    1197px 94px #fff, 53px 406px #fff, 1683px 1730px #fff, 1847px 554px #fff,
    1752px 1954px #fff, 178px 162px #fff, 368px 1830px #fff, 156px 111px #fff,
    1755px 345px #fff, 1852px 1924px #fff, 653px 13px #fff, 954px 707px #fff,
    1309px 769px #fff, 396px 1808px #fff, 1795px 1659px #fff, 249px 637px #fff,
    1019px 1603px #fff, 883px 971px #fff, 1562px 1635px #fff, 1482px 1366px #fff,
    1812px 1136px #fff, 1504px 293px #fff, 1172px 1883px #fff, 1452px 12px #fff,
    367px 1311px #fff, 1303px 268px #fff, 1659px 1894px #fff, 448px 1779px #fff,
    1523px 275px #fff, 1466px 47px #fff, 96px 1835px #fff, 705px 1978px #fff,
    1211px 34px #fff, 1861px 277px #fff, 324px 450px #fff, 131px 967px #fff,
    1730px 1888px #fff, 876px 724px #fff, 828px 781px #fff, 200px 1139px #fff,
    1284px 206px #fff, 1417px 440px #fff, 60px 1838px #fff, 1054px 1113px #fff,
    1635px 1007px #fff, 1043px 1695px #fff, 1421px 1586px #fff, 779px 282px #fff,
    473px 1868px #fff, 1509px 1310px #fff, 940px 1733px #fff, 1882px 1616px #fff,
    80px 610px #fff, 1882px 1588px #fff, 1688px 1719px #fff, 1872px 1790px #fff,
    155px 169px #fff, 857px 1970px #fff, 1126px 1974px #fff, 39px 1657px #fff,
    1374px 1080px #fff, 497px 606px #fff, 330px 1034px #fff, 84px 68px #fff,
    1141px 865px #fff, 1039px 368px #fff, 1295px 812px #fff, 1110px 1370px #fff,
    1933px 457px #fff, 1825px 792px #fff, 1050px 317px #fff, 433px 606px #fff,
    1947px 1447px #fff, 1585px 893px #fff, 963px 112px #fff, 564px 866px #fff,
    1876px 989px #fff, 975px 1395px #fff, 621px 994px #fff, 983px 1130px #fff,
    451px 114px #fff, 670px 1458px #fff, 1227px 1920px #fff, 1401px 202px #fff,
    437px 1907px #fff, 1443px 1439px #fff, 1535px 475px #fff, 393px 186px #fff,
    294px 1770px #fff, 1399px 1601px #fff, 1888px 348px #fff, 149px 1793px #fff,
    1257px 399px #fff, 1133px 1250px #fff, 1693px 790px #fff, 14px 1031px #fff,
    1672px 40px #fff, 1339px 1165px #fff, 1523px 486px #fff, 612px 1808px #fff,
    1892px 1593px #fff, 644px 1213px #fff, 1990px 1120px #fff, 1179px 175px #fff,
    105px 1119px #fff, 1319px 1371px #fff, 3px 673px #fff, 519px 1741px #fff,
    1104px 1794px #fff, 1888px 103px #fff, 149px 1468px #fff, 600px 1765px #fff,
    897px 11px #fff, 303px 1645px #fff, 348px 1422px #fff, 499px 854px #fff,
    416px 1355px #fff, 842px 848px #fff, 1342px 1032px #fff, 1906px 618px #fff,
    381px 1736px #fff, 1273px 1980px #fff, 1044px 1741px #fff,
    1331px 1885px #fff, 1392px 1770px #fff, 1972px 985px #fff, 214px 397px #fff,
    1447px 607px #fff, 1036px 589px #fff, 1478px 514px #fff, 1870px 1000px #fff,
    1906px 53px #fff, 1333px 1037px #fff, 1417px 500px #fff, 997px 14px #fff,
    1268px 1764px #fff, 1190px 1056px #fff, 1371px 611px #fff, 1269px 272px #fff,
    181px 177px #fff, 992px 1449px #fff, 138px 548px #fff, 454px 1881px #fff,
    749px 816px #fff, 1395px 525px #fff, 562px 223px #fff, 1414px 1371px #fff,
    1776px 1581px #fff, 1364px 1552px #fff, 906px 1605px #fff, 1569px 88px #fff,
    1530px 822px #fff, 859px 1748px #fff, 589px 1276px #fff, 1730px 343px #fff,
    1077px 1497px #fff, 586px 1539px #fff, 1157px 220px #fff, 62px 218px #fff,
    1602px 310px #fff, 942px 1887px #fff, 549px 1081px #fff, 1484px 1901px #fff,
    788px 959px #fff, 1986px 91px #fff, 1237px 693px #fff, 395px 1291px #fff,
    351px 625px #fff, 1379px 1980px #fff, 372px 336px #fff, 993px 216px #fff,
    432px 1380px #fff, 28px 20px #fff, 336px 759px #fff, 1835px 103px #fff,
    1069px 1473px #fff, 1803px 1119px #fff, 829px 991px #fff, 650px 168px #fff,
    61px 268px #fff, 1937px 1463px #fff, 1028px 731px #fff, 1369px 1458px #fff,
    1202px 698px #fff, 1410px 980px #fff, 521px 920px #fff, 221px 140px #fff,
    667px 1149px #fff, 1423px 1398px #fff, 721px 756px #fff, 447px 1096px #fff,
    628px 1913px #fff, 47px 923px #fff, 1711px 19px #fff, 747px 15px #fff,
    1154px 17px #fff, 875px 860px #fff, 1185px 159px #fff, 994px 1203px #fff,
    188px 1829px #fff, 550px 1861px #fff, 789px 106px #fff, 195px 1468px #fff,
    1047px 1109px #fff, 717px 144px #fff, 1561px 972px #fff, 1086px 1077px #fff,
    591px 211px #fff, 283px 683px #fff, 691px 572px #fff, 916px 895px #fff,
    205px 921px #fff, 1413px 1172px #fff, 1651px 1170px #fff, 1612px 247px #fff,
    989px 1566px #fff, 1739px 1813px #fff, 939px 180px #fff, 16px 1490px #fff,
    1050px 751px #fff, 715px 1645px #fff, 860px 1656px #fff, 1959px 1306px #fff,
    1225px 1674px #fff, 1431px 1058px #fff, 536px 284px #fff, 1431px 1405px #fff,
    1553px 175px #fff, 116px 1545px #fff, 332px 956px #fff, 69px 1060px #fff,
    188px 1576px #fff, 1646px 1901px #fff, 103px 491px #fff, 997px 1083px #fff,
    1003px 998px #fff, 47px 1572px #fff, 942px 779px #fff, 1697px 408px #fff,
    480px 610px #fff, 1879px 301px #fff, 261px 1679px #fff, 1839px 378px #fff,
    1336px 1983px #fff, 849px 1745px #fff, 1879px 1861px #fff,
    1890px 1331px #fff, 938px 1854px #fff, 835px 1655px #fff, 724px 1829px #fff,
    584px 1004px #fff, 1182px 762px #fff, 1095px 1642px #fff, 1079px 1670px #fff,
    698px 691px #fff, 659px 465px #fff, 1817px 1768px #fff, 1012px 1582px #fff,
    422px 1982px #fff, 152px 129px #fff, 809px 1586px #fff, 471px 296px #fff,
    1790px 949px #fff, 959px 1792px #fff, 1353px 150px #fff, 1859px 1944px #fff,
    506px 732px #fff, 909px 227px #fff, 1923px 126px #fff, 1191px 1362px #fff,
    1256px 278px #fff, 1617px 896px #fff, 1166px 1763px #fff, 1070px 1873px #fff,
    1730px 1044px #fff, 129px 1920px #fff, 969px 325px #fff, 1565px 903px #fff,
    1902px 1697px #fff, 1619px 960px #fff, 489px 1612px #fff, 828px 363px #fff,
    1729px 1690px #fff, 1728px 184px #fff, 826px 1747px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1536px 143px #fff, 18px 1989px #fff, 1242px 547px #fff,
    198px 1072px #fff, 1027px 1155px #fff, 1071px 43px #fff, 1241px 383px #fff,
    360px 1584px #fff, 124px 1453px #fff, 1418px 5px #fff, 1584px 437px #fff,
    1871px 1651px #fff, 397px 21px #fff, 1669px 1840px #fff, 1429px 1253px #fff,
    1314px 445px #fff, 884px 374px #fff, 1976px 1276px #fff, 893px 1158px #fff,
    777px 1215px #fff, 996px 337px #fff, 510px 142px #fff, 1481px 1800px #fff,
    702px 250px #fff, 1459px 1197px #fff, 614px 1444px #fff, 25px 1605px #fff,
    1514px 1760px #fff, 1383px 1707px #fff, 1374px 987px #fff, 1304px 315px #fff,
    1788px 1545px #fff, 1712px 1839px #fff, 97px 1572px #fff, 305px 99px #fff,
    688px 807px #fff, 691px 1591px #fff, 489px 415px #fff, 546px 542px #fff,
    527px 1635px #fff, 520px 126px #fff, 244px 1579px #fff, 1678px 1226px #fff,
    552px 951px #fff, 1492px 1249px #fff, 335px 1346px #fff, 1774px 1026px #fff,
    992px 937px #fff, 1569px 363px #fff, 268px 919px #fff, 1425px 823px #fff,
    1771px 1628px #fff, 1531px 1362px #fff, 1192px 925px #fff, 513px 597px #fff,
    1587px 1250px #fff, 144px 1259px #fff, 1083px 1737px #fff,
    1946px 1950px #fff, 395px 120px #fff, 439px 1934px #fff, 1991px 319px #fff,
    1300px 450px #fff, 1586px 1729px #fff, 559px 103px #fff, 1922px 1928px #fff,
    1199px 1132px #fff, 1191px 1043px #fff, 1341px 1525px #fff,
    1040px 559px #fff, 1724px 1503px #fff, 56px 98px #fff, 1346px 154px #fff,
    1653px 347px #fff, 296px 407px #fff, 503px 64px #fff, 104px 663px #fff,
    519px 109px #fff, 449px 1169px #fff, 1193px 1849px #fff, 751px 112px #fff,
    1397px 1724px #fff, 356px 177px #fff, 282px 147px #fff, 230px 1035px #fff,
    1418px 137px #fff, 134px 1352px #fff, 739px 1326px #fff, 259px 884px #fff,
    284px 69px #fff, 209px 879px #fff, 984px 455px #fff, 278px 1471px #fff,
    1799px 573px #fff, 972px 392px #fff, 1667px 1563px #fff, 310px 705px #fff,
    1883px 1789px #fff, 290px 1680px #fff, 1380px 13px #fff, 865px 1038px #fff,
    1178px 1200px #fff, 70px 127px #fff, 298px 912px #fff, 12px 541px #fff,
    1260px 1032px #fff, 997px 1381px #fff, 1086px 150px #fff, 686px 1932px #fff,
    933px 871px #fff, 948px 535px #fff, 522px 1410px #fff, 1295px 1776px #fff,
    951px 1465px #fff, 1683px 95px #fff, 1271px 508px #fff, 319px 852px #fff,
    43px 658px #fff, 825px 657px #fff, 1365px 1011px #fff, 601px 719px #fff,
    533px 1309px #fff, 1286px 556px #fff, 1973px 1798px #fff, 749px 183px #fff,
    606px 1948px #fff, 1196px 1248px #fff, 1984px 1986px #fff, 62px 637px #fff,
    509px 1318px #fff, 1521px 1597px #fff, 1193px 1278px #fff, 1240px 451px #fff,
    962px 1969px #fff, 1717px 262px #fff, 798px 857px #fff, 1463px 1221px #fff,
    210px 589px #fff, 561px 1947px #fff, 433px 1069px #fff, 716px 1647px #fff,
    369px 1522px #fff, 12px 689px #fff, 1532px 895px #fff, 646px 1442px #fff,
    1712px 627px #fff, 1724px 725px #fff, 1662px 88px #fff, 1143px 1236px #fff,
    137px 1380px #fff, 798px 260px #fff, 1458px 1638px #fff, 1032px 572px #fff,
    241px 1717px #fff, 1622px 177px #fff, 1891px 1663px #fff, 84px 1961px #fff,
    1254px 265px #fff, 836px 794px #fff, 102px 655px #fff, 1045px 1120px #fff,
    1254px 576px #fff, 1695px 309px #fff, 33px 748px #fff, 1024px 1426px #fff,
    1452px 854px #fff, 1783px 1306px #fff, 1091px 701px #fff, 1182px 1331px #fff,
    1115px 1622px #fff, 122px 1339px #fff, 1524px 158px #fff, 475px 966px #fff,
    1242px 1782px #fff, 32px 1212px #fff, 1192px 748px #fff, 1100px 795px #fff,
    1721px 1971px #fff, 510px 428px #fff, 657px 104px #fff, 1197px 1639px #fff,
    1410px 701px #fff, 1238px 803px #fff, 714px 1988px #fff, 1072px 784px #fff,
    1436px 744px #fff, 1672px 358px #fff, 82px 133px #fff, 260px 547px #fff,
    1921px 1659px #fff, 140px 1796px #fff, 1747px 1755px #fff,
    1216px 1459px #fff, 168px 760px #fff, 111px 1522px #fff, 1710px 1501px #fff,
    1907px 1291px #fff, 1223px 1298px #fff, 1873px 298px #fff, 1418px 54px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1536px 143px #fff, 18px 1989px #fff, 1242px 547px #fff,
    198px 1072px #fff, 1027px 1155px #fff, 1071px 43px #fff, 1241px 383px #fff,
    360px 1584px #fff, 124px 1453px #fff, 1418px 5px #fff, 1584px 437px #fff,
    1871px 1651px #fff, 397px 21px #fff, 1669px 1840px #fff, 1429px 1253px #fff,
    1314px 445px #fff, 884px 374px #fff, 1976px 1276px #fff, 893px 1158px #fff,
    777px 1215px #fff, 996px 337px #fff, 510px 142px #fff, 1481px 1800px #fff,
    702px 250px #fff, 1459px 1197px #fff, 614px 1444px #fff, 25px 1605px #fff,
    1514px 1760px #fff, 1383px 1707px #fff, 1374px 987px #fff, 1304px 315px #fff,
    1788px 1545px #fff, 1712px 1839px #fff, 97px 1572px #fff, 305px 99px #fff,
    688px 807px #fff, 691px 1591px #fff, 489px 415px #fff, 546px 542px #fff,
    527px 1635px #fff, 520px 126px #fff, 244px 1579px #fff, 1678px 1226px #fff,
    552px 951px #fff, 1492px 1249px #fff, 335px 1346px #fff, 1774px 1026px #fff,
    992px 937px #fff, 1569px 363px #fff, 268px 919px #fff, 1425px 823px #fff,
    1771px 1628px #fff, 1531px 1362px #fff, 1192px 925px #fff, 513px 597px #fff,
    1587px 1250px #fff, 144px 1259px #fff, 1083px 1737px #fff,
    1946px 1950px #fff, 395px 120px #fff, 439px 1934px #fff, 1991px 319px #fff,
    1300px 450px #fff, 1586px 1729px #fff, 559px 103px #fff, 1922px 1928px #fff,
    1199px 1132px #fff, 1191px 1043px #fff, 1341px 1525px #fff,
    1040px 559px #fff, 1724px 1503px #fff, 56px 98px #fff, 1346px 154px #fff,
    1653px 347px #fff, 296px 407px #fff, 503px 64px #fff, 104px 663px #fff,
    519px 109px #fff, 449px 1169px #fff, 1193px 1849px #fff, 751px 112px #fff,
    1397px 1724px #fff, 356px 177px #fff, 282px 147px #fff, 230px 1035px #fff,
    1418px 137px #fff, 134px 1352px #fff, 739px 1326px #fff, 259px 884px #fff,
    284px 69px #fff, 209px 879px #fff, 984px 455px #fff, 278px 1471px #fff,
    1799px 573px #fff, 972px 392px #fff, 1667px 1563px #fff, 310px 705px #fff,
    1883px 1789px #fff, 290px 1680px #fff, 1380px 13px #fff, 865px 1038px #fff,
    1178px 1200px #fff, 70px 127px #fff, 298px 912px #fff, 12px 541px #fff,
    1260px 1032px #fff, 997px 1381px #fff, 1086px 150px #fff, 686px 1932px #fff,
    933px 871px #fff, 948px 535px #fff, 522px 1410px #fff, 1295px 1776px #fff,
    951px 1465px #fff, 1683px 95px #fff, 1271px 508px #fff, 319px 852px #fff,
    43px 658px #fff, 825px 657px #fff, 1365px 1011px #fff, 601px 719px #fff,
    533px 1309px #fff, 1286px 556px #fff, 1973px 1798px #fff, 749px 183px #fff,
    606px 1948px #fff, 1196px 1248px #fff, 1984px 1986px #fff, 62px 637px #fff,
    509px 1318px #fff, 1521px 1597px #fff, 1193px 1278px #fff, 1240px 451px #fff,
    962px 1969px #fff, 1717px 262px #fff, 798px 857px #fff, 1463px 1221px #fff,
    210px 589px #fff, 561px 1947px #fff, 433px 1069px #fff, 716px 1647px #fff,
    369px 1522px #fff, 12px 689px #fff, 1532px 895px #fff, 646px 1442px #fff,
    1712px 627px #fff, 1724px 725px #fff, 1662px 88px #fff, 1143px 1236px #fff,
    137px 1380px #fff, 798px 260px #fff, 1458px 1638px #fff, 1032px 572px #fff,
    241px 1717px #fff, 1622px 177px #fff, 1891px 1663px #fff, 84px 1961px #fff,
    1254px 265px #fff, 836px 794px #fff, 102px 655px #fff, 1045px 1120px #fff,
    1254px 576px #fff, 1695px 309px #fff, 33px 748px #fff, 1024px 1426px #fff,
    1452px 854px #fff, 1783px 1306px #fff, 1091px 701px #fff, 1182px 1331px #fff,
    1115px 1622px #fff, 122px 1339px #fff, 1524px 158px #fff, 475px 966px #fff,
    1242px 1782px #fff, 32px 1212px #fff, 1192px 748px #fff, 1100px 795px #fff,
    1721px 1971px #fff, 510px 428px #fff, 657px 104px #fff, 1197px 1639px #fff,
    1410px 701px #fff, 1238px 803px #fff, 714px 1988px #fff, 1072px 784px #fff,
    1436px 744px #fff, 1672px 358px #fff, 82px 133px #fff, 260px 547px #fff,
    1921px 1659px #fff, 140px 1796px #fff, 1747px 1755px #fff,
    1216px 1459px #fff, 168px 760px #fff, 111px 1522px #fff, 1710px 1501px #fff,
    1907px 1291px #fff, 1223px 1298px #fff, 1873px 298px #fff, 1418px 54px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1833px 1616px #fff, 546px 717px #fff, 285px 1613px #fff,
    933px 1170px #fff, 679px 1414px #fff, 1840px 1819px #fff, 1204px 1441px #fff,
    601px 1786px #fff, 161px 980px #fff, 476px 860px #fff, 1786px 446px #fff,
    155px 1790px #fff, 1236px 1605px #fff, 888px 1501px #fff, 986px 624px #fff,
    245px 345px #fff, 1625px 1159px #fff, 731px 788px #fff, 1406px 1998px #fff,
    1754px 1782px #fff, 477px 561px #fff, 1982px 299px #fff, 1932px 1913px #fff,
    439px 963px #fff, 1153px 148px #fff, 1602px 948px #fff, 582px 193px #fff,
    1033px 920px #fff, 1604px 136px #fff, 671px 1285px #fff, 1917px 1645px #fff,
    186px 492px #fff, 1291px 584px #fff, 765px 1307px #fff, 277px 1907px #fff,
    1153px 50px #fff, 1557px 272px #fff, 600px 1582px #fff, 997px 517px #fff,
    1082px 1257px #fff, 1402px 823px #fff, 612px 1504px #fff, 1852px 876px #fff,
    1256px 943px #fff, 1224px 1011px #fff, 147px 1789px #fff, 34px 232px #fff,
    643px 1137px #fff, 1008px 949px #fff, 985px 1947px #fff, 74px 574px #fff,
    146px 1167px #fff, 90px 427px #fff, 409px 1942px #fff, 770px 1891px #fff,
    197px 365px #fff, 51px 1035px #fff, 1122px 1479px #fff, 164px 1469px #fff,
    493px 1398px #fff, 604px 1163px #fff, 173px 1655px #fff, 734px 1522px #fff,
    1708px 24px #fff, 1200px 964px #fff, 1189px 818px #fff, 757px 1220px #fff,
    1903px 806px #fff, 7px 1799px #fff, 1797px 1736px #fff, 644px 578px #fff,
    947px 587px #fff, 353px 1190px #fff, 104px 796px #fff, 285px 798px #fff,
    1011px 1658px #fff, 14px 302px #fff, 1592px 1892px #fff, 1573px 552px #fff,
    1453px 1794px #fff, 1878px 1796px #fff, 424px 301px #fff, 63px 1165px #fff,
    984px 1078px #fff, 407px 1674px #fff, 1287px 1200px #fff, 670px 1843px #fff,
    1938px 868px #fff, 1918px 495px #fff, 892px 198px #fff, 645px 1239px #fff,
    432px 1135px #fff, 567px 159px #fff, 369px 874px #fff, 374px 1440px #fff,
    332px 1749px #fff, 629px 1952px #fff, 658px 1334px #fff, 742px 363px #fff,
    1785px 707px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1833px 1616px #fff, 546px 717px #fff, 285px 1613px #fff,
    933px 1170px #fff, 679px 1414px #fff, 1840px 1819px #fff, 1204px 1441px #fff,
    601px 1786px #fff, 161px 980px #fff, 476px 860px #fff, 1786px 446px #fff,
    155px 1790px #fff, 1236px 1605px #fff, 888px 1501px #fff, 986px 624px #fff,
    245px 345px #fff, 1625px 1159px #fff, 731px 788px #fff, 1406px 1998px #fff,
    1754px 1782px #fff, 477px 561px #fff, 1982px 299px #fff, 1932px 1913px #fff,
    439px 963px #fff, 1153px 148px #fff, 1602px 948px #fff, 582px 193px #fff,
    1033px 920px #fff, 1604px 136px #fff, 671px 1285px #fff, 1917px 1645px #fff,
    186px 492px #fff, 1291px 584px #fff, 765px 1307px #fff, 277px 1907px #fff,
    1153px 50px #fff, 1557px 272px #fff, 600px 1582px #fff, 997px 517px #fff,
    1082px 1257px #fff, 1402px 823px #fff, 612px 1504px #fff, 1852px 876px #fff,
    1256px 943px #fff, 1224px 1011px #fff, 147px 1789px #fff, 34px 232px #fff,
    643px 1137px #fff, 1008px 949px #fff, 985px 1947px #fff, 74px 574px #fff,
    146px 1167px #fff, 90px 427px #fff, 409px 1942px #fff, 770px 1891px #fff,
    197px 365px #fff, 51px 1035px #fff, 1122px 1479px #fff, 164px 1469px #fff,
    493px 1398px #fff, 604px 1163px #fff, 173px 1655px #fff, 734px 1522px #fff,
    1708px 24px #fff, 1200px 964px #fff, 1189px 818px #fff, 757px 1220px #fff,
    1903px 806px #fff, 7px 1799px #fff, 1797px 1736px #fff, 644px 578px #fff,
    947px 587px #fff, 353px 1190px #fff, 104px 796px #fff, 285px 798px #fff,
    1011px 1658px #fff, 14px 302px #fff, 1592px 1892px #fff, 1573px 552px #fff,
    1453px 1794px #fff, 1878px 1796px #fff, 424px 301px #fff, 63px 1165px #fff,
    984px 1078px #fff, 407px 1674px #fff, 1287px 1200px #fff, 670px 1843px #fff,
    1938px 868px #fff, 1918px 495px #fff, 892px 198px #fff, 645px 1239px #fff,
    432px 1135px #fff, 567px 159px #fff, 369px 874px #fff, 374px 1440px #fff,
    332px 1749px #fff, 629px 1952px #fff, 658px 1334px #fff, 742px 363px #fff,
    1785px 707px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
