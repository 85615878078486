/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
body {
  z-index: -1;
  margin: 0px;
  padding: 0px;
  color: white;
  height: 100%;
  /* background-color: #fff; */
  background: #270268;
  /* font-family: "Baloo Da 2", cursive; */
  font-family: Roboto, Helvetica, Arial, sans-serif;
  position: relative;
}
