.containerform {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100vh;
  max-height: 200vh;
  overflow: auto;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}


.picture {
  width: 40%;
  /* border: solid 1px black; */
  z-index: 1;
  max-height: 200vh;
  height: 200vh;
}
/* .picture img {
  width: 450px;
  margin-top: 250px;
  max-height: 1700px;
  height: 100%;
} */

.picture p {
  font-size: 25px;
  position: absolute;
  top: 2%;
  margin-bottom: 10%;
  margin-left: 2%;
  text-align: center;
}
.form {
  background: #fff;
 
  width: 100%;

  height: 230vh;
}
.formulaire {
  /* max-height: 200vh; */
  padding: 50px 150px;
  /* height: 100%; */
}
.btn-retour {
  background-color: #8d69cb;
  color: #fff;
  border: none;
  font-family: "Baloo Da 2", cursive;
  font-size: 1.1rem;
  position: absolute;
  bottom: 0;
  margin-bottom: 2rem;
  margin-left: 22%;
}
.btn-retour :hover {
  background-color:#ffff !important;
  color: #270268 ;
  }