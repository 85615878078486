@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300&display=swap");
.wrapperr {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  background: #eee;
}
.wrapperr .text_group .text_404 {
  font-family: "Comic Neue", cursive;
  font-size: 10em;
  box-sizing: border-box;
  color: #363636;
}
.wrapperr .text_group .text_lost {
  font-family: "Comic Neue", cursive;
  font-size: 2em;
  line-height: 50px;
  box-sizing: border-box;
  color: #565656;
}
.wrapperr .window_group .window_404 {
  width: 200px;
  height: 350px;
  border-radius: 100px;
  box-shadow: -3px -3px 0px 5px #d4d4d4, 5px 5px 0px 2px white;
  background: linear-gradient(310deg, #020024 0%, #09096b 0%, black 80%);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.wrapperr .window_group .window_404 .stars {
  width: 400px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  animation: flyby 30s linear infinite;
}
.wrapperr .window_group .window_404 .stars .star {
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(1) {
  width: 3px;
  height: 3px;
  left: 269px;
  top: 41px;
  animation: twinkle1 10s linear infinite;
  animation-delay: 4s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(1):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(2) {
  width: 2px;
  height: 2px;
  left: 217px;
  top: 188px;
  animation: twinkle2 9s linear infinite;
  animation-delay: 17s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(2):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(3) {
  width: 1px;
  height: 1px;
  left: 338px;
  top: 117px;
  animation: twinkle3 7s linear infinite;
  animation-delay: 14s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(3):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(4) {
  width: 3px;
  height: 3px;
  left: 150px;
  top: 295px;
  animation: twinkle4 9s linear infinite;
  animation-delay: 15s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(4):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(5) {
  width: 3px;
  height: 3px;
  left: 337px;
  top: 35px;
  animation: twinkle5 10s linear infinite;
  animation-delay: 16s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(5):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(6) {
  width: 3px;
  height: 3px;
  left: 291px;
  top: 239px;
  animation: twinkle6 12s linear infinite;
  animation-delay: 12s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(6):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(7) {
  width: 1px;
  height: 1px;
  left: 260px;
  top: 249px;
  animation: twinkle7 8s linear infinite;
  animation-delay: 10s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(7):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(8) {
  width: 3px;
  height: 3px;
  left: 278px;
  top: 248px;
  animation: twinkle8 12s linear infinite;
  animation-delay: 5s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(8):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(9) {
  width: 1px;
  height: 1px;
  left: 95px;
  top: 151px;
  animation: twinkle9 8s linear infinite;
  animation-delay: 4s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(9):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(10) {
  width: 1px;
  height: 1px;
  left: 305px;
  top: 164px;
  animation: twinkle10 8s linear infinite;
  animation-delay: 14s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(10):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(11) {
  width: 1px;
  height: 1px;
  left: 243px;
  top: 164px;
  animation: twinkle11 12s linear infinite;
  animation-delay: 14s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(11):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(12) {
  width: 1px;
  height: 1px;
  left: 394px;
  top: 231px;
  animation: twinkle12 8s linear infinite;
  animation-delay: 11s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(12):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(13) {
  width: 1px;
  height: 1px;
  left: 353px;
  top: 262px;
  animation: twinkle13 9s linear infinite;
  animation-delay: 9s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(13):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(14) {
  width: 3px;
  height: 3px;
  left: 182px;
  top: 262px;
  animation: twinkle14 8s linear infinite;
  animation-delay: 7s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(14):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(15) {
  width: 1px;
  height: 1px;
  left: 326px;
  top: 132px;
  animation: twinkle15 12s linear infinite;
  animation-delay: 17s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(15):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(16) {
  width: 3px;
  height: 3px;
  left: 229px;
  top: 80px;
  animation: twinkle16 7s linear infinite;
  animation-delay: 5s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(16):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(17) {
  width: 1px;
  height: 1px;
  left: 210px;
  top: 112px;
  animation: twinkle17 10s linear infinite;
  animation-delay: 9s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(17):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(18) {
  width: 1px;
  height: 1px;
  left: 58px;
  top: 271px;
  animation: twinkle18 13s linear infinite;
  animation-delay: 16s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(18):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(19) {
  width: 3px;
  height: 3px;
  left: 4px;
  top: 21px;
  animation: twinkle19 10s linear infinite;
  animation-delay: 9s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(19):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(20) {
  width: 3px;
  height: 3px;
  left: 283px;
  top: 322px;
  animation: twinkle20 6s linear infinite;
  animation-delay: 10s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(20):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(21) {
  width: 1px;
  height: 1px;
  left: 5px;
  top: 218px;
  animation: twinkle21 13s linear infinite;
  animation-delay: 11s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(21):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(22) {
  width: 3px;
  height: 3px;
  left: 224px;
  top: 70px;
  animation: twinkle22 11s linear infinite;
  animation-delay: 18s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(22):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(23) {
  width: 2px;
  height: 2px;
  left: 86px;
  top: 93px;
  animation: twinkle23 12s linear infinite;
  animation-delay: 12s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(23):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(24) {
  width: 3px;
  height: 3px;
  left: 399px;
  top: 5px;
  animation: twinkle24 6s linear infinite;
  animation-delay: 18s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(24):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(25) {
  width: 1px;
  height: 1px;
  left: 55px;
  top: 197px;
  animation: twinkle25 11s linear infinite;
  animation-delay: 9s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(25):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(26) {
  width: 1px;
  height: 1px;
  left: 372px;
  top: 250px;
  animation: twinkle26 8s linear infinite;
  animation-delay: 5s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(26):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(27) {
  width: 2px;
  height: 2px;
  left: 54px;
  top: 189px;
  animation: twinkle27 10s linear infinite;
  animation-delay: 16s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(27):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(28) {
  width: 3px;
  height: 3px;
  left: 242px;
  top: 45px;
  animation: twinkle28 12s linear infinite;
  animation-delay: 17s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(28):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(29) {
  width: 3px;
  height: 3px;
  left: 265px;
  top: 224px;
  animation: twinkle29 8s linear infinite;
  animation-delay: 10s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(29):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(30) {
  width: 1px;
  height: 1px;
  left: 263px;
  top: 140px;
  animation: twinkle30 7s linear infinite;
  animation-delay: 15s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(30):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(31) {
  width: 2px;
  height: 2px;
  left: 343px;
  top: 79px;
  animation: twinkle31 12s linear infinite;
  animation-delay: 10s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(31):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(32) {
  width: 3px;
  height: 3px;
  left: 63px;
  top: 8px;
  animation: twinkle32 11s linear infinite;
  animation-delay: 7s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(32):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(33) {
  width: 3px;
  height: 3px;
  left: 22px;
  top: 209px;
  animation: twinkle33 11s linear infinite;
  animation-delay: 15s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(33):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(34) {
  width: 2px;
  height: 2px;
  left: 380px;
  top: 4px;
  animation: twinkle34 13s linear infinite;
  animation-delay: 16s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(34):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(35) {
  width: 2px;
  height: 2px;
  left: 237px;
  top: 214px;
  animation: twinkle35 12s linear infinite;
  animation-delay: 16s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(35):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(36) {
  width: 1px;
  height: 1px;
  left: 74px;
  top: 251px;
  animation: twinkle36 8s linear infinite;
  animation-delay: 14s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(36):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(37) {
  width: 1px;
  height: 1px;
  left: 57px;
  top: 181px;
  animation: twinkle37 13s linear infinite;
  animation-delay: 16s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(37):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(38) {
  width: 3px;
  height: 3px;
  left: 32px;
  top: 265px;
  animation: twinkle38 12s linear infinite;
  animation-delay: 9s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(38):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(39) {
  width: 1px;
  height: 1px;
  left: 127px;
  top: 93px;
  animation: twinkle39 12s linear infinite;
  animation-delay: 4s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(39):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(40) {
  width: 1px;
  height: 1px;
  left: 373px;
  top: 141px;
  animation: twinkle40 7s linear infinite;
  animation-delay: 9s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(40):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(41) {
  width: 2px;
  height: 2px;
  left: 288px;
  top: 254px;
  animation: twinkle41 12s linear infinite;
  animation-delay: 17s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(41):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(42) {
  width: 2px;
  height: 2px;
  left: 158px;
  top: 318px;
  animation: twinkle42 13s linear infinite;
  animation-delay: 11s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(42):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(43) {
  width: 2px;
  height: 2px;
  left: 379px;
  top: 8px;
  animation: twinkle43 10s linear infinite;
  animation-delay: 16s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(43):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(44) {
  width: 1px;
  height: 1px;
  left: 292px;
  top: 325px;
  animation: twinkle44 9s linear infinite;
  animation-delay: 18s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(44):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(45) {
  width: 3px;
  height: 3px;
  left: 103px;
  top: 13px;
  animation: twinkle45 10s linear infinite;
  animation-delay: 17s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(45):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(46) {
  width: 3px;
  height: 3px;
  left: 212px;
  top: 86px;
  animation: twinkle46 8s linear infinite;
  animation-delay: 4s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(46):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(47) {
  width: 2px;
  height: 2px;
  left: 302px;
  top: 35px;
  animation: twinkle47 12s linear infinite;
  animation-delay: 13s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(47):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(48) {
  width: 1px;
  height: 1px;
  left: 100px;
  top: 126px;
  animation: twinkle48 7s linear infinite;
  animation-delay: 11s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(48):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(49) {
  width: 2px;
  height: 2px;
  left: 37px;
  top: 168px;
  animation: twinkle49 6s linear infinite;
  animation-delay: 10s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(49):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(50) {
  width: 1px;
  height: 1px;
  left: 327px;
  top: 222px;
  animation: twinkle50 9s linear infinite;
  animation-delay: 17s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(50):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(51) {
  width: 1px;
  height: 1px;
  left: 392px;
  top: 301px;
  animation: twinkle51 11s linear infinite;
  animation-delay: 6s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(51):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(52) {
  width: 2px;
  height: 2px;
  left: 203px;
  top: 155px;
  animation: twinkle52 11s linear infinite;
  animation-delay: 6s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(52):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(53) {
  width: 2px;
  height: 2px;
  left: 204px;
  top: 298px;
  animation: twinkle53 9s linear infinite;
  animation-delay: 11s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(53):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(54) {
  width: 1px;
  height: 1px;
  left: 198px;
  top: 68px;
  animation: twinkle54 12s linear infinite;
  animation-delay: 9s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(54):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(55) {
  width: 2px;
  height: 2px;
  left: 47px;
  top: 213px;
  animation: twinkle55 6s linear infinite;
  animation-delay: 16s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(55):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(56) {
  width: 2px;
  height: 2px;
  left: 171px;
  top: 306px;
  animation: twinkle56 13s linear infinite;
  animation-delay: 8s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(56):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(57) {
  width: 2px;
  height: 2px;
  left: 308px;
  top: 214px;
  animation: twinkle57 10s linear infinite;
  animation-delay: 6s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(57):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(58) {
  width: 2px;
  height: 2px;
  left: 140px;
  top: 8px;
  animation: twinkle58 9s linear infinite;
  animation-delay: 12s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(58):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(59) {
  width: 1px;
  height: 1px;
  left: 160px;
  top: 142px;
  animation: twinkle59 8s linear infinite;
  animation-delay: 8s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(59):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(60) {
  width: 2px;
  height: 2px;
  left: 70px;
  top: 85px;
  animation: twinkle60 12s linear infinite;
  animation-delay: 6s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(60):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(61) {
  width: 2px;
  height: 2px;
  left: 270px;
  top: 161px;
  animation: twinkle61 9s linear infinite;
  animation-delay: 5s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(61):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(62) {
  width: 1px;
  height: 1px;
  left: 206px;
  top: 337px;
  animation: twinkle62 11s linear infinite;
  animation-delay: 12s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(62):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(63) {
  width: 3px;
  height: 3px;
  left: 289px;
  top: 317px;
  animation: twinkle63 6s linear infinite;
  animation-delay: 13s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(63):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(64) {
  width: 1px;
  height: 1px;
  left: 291px;
  top: 278px;
  animation: twinkle64 13s linear infinite;
  animation-delay: 6s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(64):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(65) {
  width: 2px;
  height: 2px;
  left: 178px;
  top: 227px;
  animation: twinkle65 6s linear infinite;
  animation-delay: 15s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(65):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(66) {
  width: 2px;
  height: 2px;
  left: 33px;
  top: 94px;
  animation: twinkle66 9s linear infinite;
  animation-delay: 5s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(66):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(67) {
  width: 3px;
  height: 3px;
  left: 196px;
  top: 135px;
  animation: twinkle67 6s linear infinite;
  animation-delay: 10s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(67):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(68) {
  width: 3px;
  height: 3px;
  left: 365px;
  top: 135px;
  animation: twinkle68 9s linear infinite;
  animation-delay: 16s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(68):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(69) {
  width: 3px;
  height: 3px;
  left: 291px;
  top: 309px;
  animation: twinkle69 10s linear infinite;
  animation-delay: 4s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(69):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(70) {
  width: 1px;
  height: 1px;
  left: 376px;
  top: 186px;
  animation: twinkle70 6s linear infinite;
  animation-delay: 5s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(70):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(71) {
  width: 1px;
  height: 1px;
  left: 222px;
  top: 222px;
  animation: twinkle71 8s linear infinite;
  animation-delay: 12s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(71):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(72) {
  width: 3px;
  height: 3px;
  left: 8px;
  top: 106px;
  animation: twinkle72 7s linear infinite;
  animation-delay: 11s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(72):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(73) {
  width: 2px;
  height: 2px;
  left: 72px;
  top: 198px;
  animation: twinkle73 13s linear infinite;
  animation-delay: 10s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(73):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(74) {
  width: 2px;
  height: 2px;
  left: 264px;
  top: 297px;
  animation: twinkle74 13s linear infinite;
  animation-delay: 18s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(74):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(75) {
  width: 2px;
  height: 2px;
  left: 318px;
  top: 278px;
  animation: twinkle75 10s linear infinite;
  animation-delay: 15s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(75):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(76) {
  width: 3px;
  height: 3px;
  left: 315px;
  top: 193px;
  animation: twinkle76 10s linear infinite;
  animation-delay: 10s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(76):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(77) {
  width: 2px;
  height: 2px;
  left: 321px;
  top: 299px;
  animation: twinkle77 11s linear infinite;
  animation-delay: 14s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(77):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(78) {
  width: 2px;
  height: 2px;
  left: 352px;
  top: 172px;
  animation: twinkle78 6s linear infinite;
  animation-delay: 8s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(78):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(79) {
  width: 2px;
  height: 2px;
  left: 49px;
  top: 7px;
  animation: twinkle79 13s linear infinite;
  animation-delay: 14s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(79):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(80) {
  width: 1px;
  height: 1px;
  left: 188px;
  top: 102px;
  animation: twinkle80 8s linear infinite;
  animation-delay: 14s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(80):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(81) {
  width: 1px;
  height: 1px;
  left: 158px;
  top: 223px;
  animation: twinkle81 13s linear infinite;
  animation-delay: 9s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(81):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(82) {
  width: 3px;
  height: 3px;
  left: 366px;
  top: 189px;
  animation: twinkle82 7s linear infinite;
  animation-delay: 6s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(82):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(83) {
  width: 1px;
  height: 1px;
  left: 149px;
  top: 164px;
  animation: twinkle83 6s linear infinite;
  animation-delay: 13s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(83):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(84) {
  width: 3px;
  height: 3px;
  left: 398px;
  top: 194px;
  animation: twinkle84 10s linear infinite;
  animation-delay: 11s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(84):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(85) {
  width: 1px;
  height: 1px;
  left: 68px;
  top: 110px;
  animation: twinkle85 9s linear infinite;
  animation-delay: 6s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(85):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(86) {
  width: 2px;
  height: 2px;
  left: 98px;
  top: 22px;
  animation: twinkle86 11s linear infinite;
  animation-delay: 4s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(86):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(87) {
  width: 1px;
  height: 1px;
  left: 335px;
  top: 31px;
  animation: twinkle87 9s linear infinite;
  animation-delay: 4s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(87):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(88) {
  width: 1px;
  height: 1px;
  left: 35px;
  top: 328px;
  animation: twinkle88 13s linear infinite;
  animation-delay: 16s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(88):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(89) {
  width: 2px;
  height: 2px;
  left: 110px;
  top: 199px;
  animation: twinkle89 8s linear infinite;
  animation-delay: 15s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(89):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(90) {
  width: 2px;
  height: 2px;
  left: 270px;
  top: 191px;
  animation: twinkle90 10s linear infinite;
  animation-delay: 5s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(90):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(91) {
  width: 2px;
  height: 2px;
  left: 362px;
  top: 189px;
  animation: twinkle91 11s linear infinite;
  animation-delay: 5s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(91):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(92) {
  width: 2px;
  height: 2px;
  left: 289px;
  top: 68px;
  animation: twinkle92 8s linear infinite;
  animation-delay: 9s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(92):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(93) {
  width: 3px;
  height: 3px;
  left: 189px;
  top: 30px;
  animation: twinkle93 7s linear infinite;
  animation-delay: 8s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(93):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(94) {
  width: 1px;
  height: 1px;
  left: 306px;
  top: 121px;
  animation: twinkle94 10s linear infinite;
  animation-delay: 18s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(94):before {
  content: "";
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(95) {
  width: 2px;
  height: 2px;
  left: 271px;
  top: 172px;
  animation: twinkle95 12s linear infinite;
  animation-delay: 8s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(95):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(96) {
  width: 2px;
  height: 2px;
  left: 138px;
  top: 249px;
  animation: twinkle96 13s linear infinite;
  animation-delay: 7s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(96):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(97) {
  width: 3px;
  height: 3px;
  left: 111px;
  top: 154px;
  animation: twinkle97 12s linear infinite;
  animation-delay: 15s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(97):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(98) {
  width: 3px;
  height: 3px;
  left: 331px;
  top: 330px;
  animation: twinkle98 11s linear infinite;
  animation-delay: 14s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(98):before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(99) {
  width: 2px;
  height: 2px;
  left: 363px;
  top: 95px;
  animation: twinkle99 7s linear infinite;
  animation-delay: 13s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(99):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(100) {
  width: 2px;
  height: 2px;
  left: 13px;
  top: 339px;
  animation: twinkle100 11s linear infinite;
  animation-delay: 18s;
}
.wrapperr .window_group .window_404 .stars .star:nth-of-type(100):before {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  filter: blur(1px);
}

@keyframes twinkle1 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle2 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle3 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle4 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle5 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle6 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle7 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle8 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle9 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle10 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle11 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle12 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle13 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle14 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle15 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle16 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle17 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle18 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle19 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle20 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle21 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle22 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle23 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle24 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle25 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle26 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle27 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle28 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle29 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle30 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle31 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle32 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle33 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle34 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle35 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle36 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle37 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle38 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle39 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle40 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle41 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle42 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle43 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle44 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle45 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle46 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle47 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle48 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle49 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle50 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle51 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle52 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle53 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle54 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle55 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle56 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle57 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle58 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle59 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle60 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle61 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle62 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle63 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle64 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle65 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle66 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle67 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle68 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle69 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle70 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle71 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle72 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle73 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle74 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle75 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle76 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle77 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle78 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle79 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle80 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle81 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle82 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle83 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle84 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle85 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle86 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle87 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle88 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle89 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle90 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle91 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle92 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle93 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle94 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle95 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle96 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle97 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle98 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle99 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes twinkle100 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.3, 0.3);
  }
  20% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.5, 0.5);
  }
  40% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes flyby {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}
@media only screen and (min-width: 1080px) {
  .wrapperr .text_group {
    flex: 0 0 30%;
    margin-left: 25%;
    align-items: flex-end;
  }
  .wrapperr .window_group {
    flex: 1 0 40%;
    margin-top: 0;
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1079px) {
  .wrapperr .text_group {
    flex: 0 0 100%;
    margin: 0;
    align-items: center;
  }
  .wrapperr .text_group .text_lost {
    width: 100%;
    padding: 0 2em;
    font-size: 1.7em;
    line-height: 2.5em;
  }
  .wrapperr .window_group {
    flex: 0 0 100%;
  }
  .wrapperr .window_group .window_404 {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 10%;
    margin-bottom: 10%;
  }
}